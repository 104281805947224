import { stringify } from 'querystring';
import { REPORT_DESIGN_DOMAIN, REPORT_SYSTEM_DOMAIN } from '@/utils/CONST';

export function getLoginPath() {
  const queryString = stringify({
    redirect: window.location.href,
  });
  return `/user/login?${queryString}`;
}

export function toLogin() {
  const loginPath = getLoginPath();
  window.location.href = `/#${loginPath}`;
}

export function goLogin() {
  window.location.href = '/#/user/login';
}

export function goUsers() {
  window.location.href = '/#/users';
}

export const REPORT_DESIGN_URL = REPORT_DESIGN_DOMAIN;

export const REPORT_SYSTEM_URL = REPORT_SYSTEM_DOMAIN;
