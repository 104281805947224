import React from 'react';

class DDlogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: 'https://login.dingtalk.com/login/qrcode.htm?goto=',
    };
  }

  createSrc = () => {
    const { goto, style, href } = this.props.options;
    let src = this.state.url + encodeURIComponent(goto);
    src += style ? `&style=${style}` : '';
    src += href ? `&href=${href}` : '';
    return src;
  };

  render() {
    const iframeOption = {
      id: this.props.options.id,
      src: this.createSrc(),
      frameBorder: '0',
      allowtransparency: 'true',
      scrolling: 'no',
      width: this.props.options.width,
      height: this.props.options.height,
      className: this.props.className,
    };
    return <iframe title="shubotong" {...iframeOption} />;
  }
}

export default DDlogin;
