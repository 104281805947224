import qs from 'qs';
import request from '@/utils/request';
import { API_DOMAIN } from './const';

export async function queryUserList() {
  return request(`${API_DOMAIN}/user/userList`);
}

export async function toggleUserActivated(params) {
  return request.post(`${API_DOMAIN}/user/toggleActivated?${qs.stringify(params)}`);
}

export async function queryUserShowDemo() {
  return request(`${API_DOMAIN}/user/showDemo`);
}

export async function toggleShowDemo() {
  return request.post(`${API_DOMAIN}/user/toggleShowDemo`);
}
