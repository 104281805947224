import React from 'react';
import { connect } from 'dva';

import { Radio, Button, message } from 'antd';

import { goLogin } from '@/utils/redirect';
import { getCookieUsers } from '@/utils/cookies';

import styles from './style.less';

function ifCookieUsersExistWillParse() {
  let users = getCookieUsers() || [];
  if (users && users.length > 0) {
    users = JSON.parse(decodeURIComponent(getCookieUsers()));
  }
  return users;
}

function cookieUsersLength() {
  const users = ifCookieUsersExistWillParse();
  return users.length;
}

class SelectCorp extends React.Component {
  state = {
    selectedCorpId: cookieUsersLength() > 0 ? ifCookieUsersExistWillParse()[0].corpId : '',
  };

  componentDidMount() {
    setTimeout(() => {
      const users = ifCookieUsersExistWillParse();
      if (users.length === 0) {
        message.warning('您还没有参与任何组织');
        goLogin();
      }
      if (users.length === 1) {
        const currentUser = users[0];
        const { userId, corpId } = currentUser;
        this.authLogin({
          userId,
          corpId,
        });
      }
    }, 300);
  }

  authLogin = params => {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch({
        type: 'selectCorp/authLogin',
        params,
      });
    }
  };

  onChange = e => {
    this.setState({
      selectedCorpId: e.target.value,
    });
  };

  handleLogin = () => {
    const users = ifCookieUsersExistWillParse();
    if (users.length > 0) {
      const { userId, corpId } = users.filter(item => this.state.selectedCorpId === item.corpId)[0];
      this.authLogin({
        userId,
        corpId,
      });
    }
  };

  render() {
    const users = ifCookieUsersExistWillParse();
    return (
      <div className={styles.pgLogin}>
        <section className={styles.body}>
          <div className={styles.left}>
            <div className={styles.bgFilm}></div>
            <div className={styles.bodyContent}>
              <header className={styles.bodyHeader}>
                <img
                  className={styles.logoImg}
                  src="https://shubotong.oss-cn-hangzhou.aliyuncs.com/logoFontt.png"
                  alt=""
                />
              </header>
              <section className={styles.bodySection}>
                <img
                  className={styles.loginBgImage}
                  src="https://report-image-list.oss-cn-hangzhou.aliyuncs.com/dingtalk-admin-pic-tu.png"
                  alt=""
                />
              </section>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.loginQrImage}>
              <h3>请您选择一个企业后再进行登陆</h3>
              <div style={{ height: '12px' }}></div>
              {users.length > 0 ? (
                <>
                  <Radio.Group
                    className={styles.loginGroupWrapper}
                    onChange={this.onChange}
                    value={this.state.selectedCorpId}
                  >
                    {users.map(user => (
                      <div key={user.corpId}>
                        <Radio value={user.corpId}>{user.corpName}</Radio>
                        <div style={{ height: '8px' }}></div>
                      </div>
                    ))}
                  </Radio.Group>
                  <div style={{ height: '12px' }}></div>
                  <Button type="primary" block onClick={this.handleLogin}>
                    登录
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default connect(({ loading }) => ({
  loading: loading.models.selectCorp,
}))(SelectCorp);
