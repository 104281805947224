import React from 'react';

// unregister service worker
const { serviceWorker } = navigator;

if (serviceWorker.getRegistrations) {
  serviceWorker.getRegistrations().then(sws => {
    sws.forEach(sw => {
      sw.unregister();
    });
  });
}

serviceWorker.getRegistration().then(sw => {
  if (sw) sw.unregister();
});

// remove all caches
if (window.caches && window.caches.keys) {
  caches.keys().then(keys => {
    keys.forEach(key => {
      caches.delete(key);
    });
  });
}
