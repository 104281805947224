import React from 'react';
import { connect } from 'dva';
import { Spin, Switch, Card, Transfer } from 'antd';
import { diffAry } from '@/utils/utils';

import styles from './style.less';

class Users extends React.Component {
  componentDidMount() {
    this.getUserList();
    this.getIsShowDemo();
  }

  getUserList = () => {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch({
        type: 'users/fetchUserList',
      });
    }
  };

  getIsShowDemo = () => {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch({
        type: 'users/fetchIsShowDemo',
      });
    }
  };

  toggleShowDemo = () => {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch({
        type: 'users/toggleShowDemo',
      });
    }
  };

  handleChange = targetKeys => {
    const { dispatch, userList } = this.props;
    const ids = diffAry(userList.targetKeys, targetKeys);
    if (dispatch) {
      dispatch({
        type: 'users/toggleUserActivated',
        params: { ids: ids.join(',') },
      });
    }
  };

  handleChangeShowDemo = () => {
    this.toggleShowDemo();
  };

  render() {
    const { loading, userList, isShowDemo } = this.props;
    return (
      <Spin spinning={loading} delay={300}>
        <header className={styles.usersHeader}>
          <span className={styles.headerTxt}>1. 开启示例报表</span>
          <Switch
            checkedChildren="开"
            unCheckedChildren="关"
            checked={isShowDemo}
            onChange={this.handleChangeShowDemo}
          />
        </header>
        <div style={{ color: 'black', padding: '0 24px 0 24px', background: 'white' }}>
          2. 分配编辑权限
        </div>
        <Card>
          <Transfer
            dataSource={userList.dataSource}
            showSearch
            listStyle={{
              width: 'calc(50% - 60px)',
              height: 'calc(100vh - 200px)',
            }}
            operations={['授权', '撤销']}
            targetKeys={userList.targetKeys}
            onChange={this.handleChange}
            render={item => `${item.name}`}
          />
        </Card>
      </Spin>
    );
  }
}

export default connect(({ users, loading }) => ({
  userList: users.userList,
  isShowDemo: users.isShowDemo,
  loading: loading.models.users,
}))(Users);
