export const SERVER_DOMAIN = {
  test: 'https://dingtalk-server-test.chartmo.com',
  development: 'https://dingtalk-server-dev.chartmo.com',
  prepublish: 'https://dingtalk-server-pre.chartmo.com',
  production: 'https://dingtalk-server.chartmo.com',
}[process.env.TYPE];

export const REPORT_DESIGN_DOMAIN = {
  test: 'https://dingtalk-designer-test.chartmo.com/#/',
  development: 'https://dingtalk-designer-dev.chartmo.com/#/',
  prepublish: 'https://dingtalk-designer-pre.chartmo.com/#/',
  production: 'https://dingtalk-designer.chartmo.com/#/',
}[process.env.TYPE];

export const REPORT_SYSTEM_DOMAIN = {
  test: 'https://dingtalk-webconsole-test.chartmo.com/#/',
  development: 'https://dingtalk-webconsole-dev.chartmo.com/#/',
  prepublish: 'https://dingtalk-webconsole-pre.chartmo.com/#/',
  production: 'https://dingtalk-webconsole.chartmo.com/#/',
}[process.env.TYPE];

export const APPID = {
  test: 'dingoafgftj7qjflef1lyc',
  development: 'dingoaofx3aff1q5py5ckf',
  prepublish: 'dingoagto3qcpfcu9d6tgu',
  production: 'dingoatgfla7wdc7wvzvge',
}[process.env.TYPE];
