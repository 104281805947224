import Cookies from 'js-cookie';

const COOKIE_TOKEN = 'token';
const COOKIE_IS_ADMIN = 'isAdmin';
const COOKIE_IS_PAY = 'isPay';
const COOKIE_USERS = 'users';
const SERVER_DOMAIN = '.chartmo.com';

export function getCookieToken() {
  return Cookies.get(COOKIE_TOKEN, { domain: SERVER_DOMAIN }) || '';
}

export function getCookieIsAdmin() {
  return Cookies.get(COOKIE_IS_ADMIN, { domain: SERVER_DOMAIN }) || '';
}

export function getCookieIsPay() {
  return Cookies.get(COOKIE_IS_PAY, { domain: SERVER_DOMAIN }) || '';
}

export function getCookieUsers() {
  return Cookies.get(COOKIE_USERS, { domain: SERVER_DOMAIN }) || '';
}

export function removeCookieForLogout() {
  Cookies.remove(COOKIE_TOKEN, { domain: SERVER_DOMAIN });
  Cookies.remove(COOKIE_USERS, { domain: SERVER_DOMAIN });
  Cookies.remove(COOKIE_IS_PAY, { domain: SERVER_DOMAIN });
  Cookies.remove(COOKIE_IS_ADMIN, { domain: SERVER_DOMAIN });
}
