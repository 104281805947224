import { message } from 'antd';
import { authLogin } from '@/services/selectCorp';
import { goUsers } from '@/utils/redirect';

const SelectCorpModel = {
  namespace: 'selectCorp',
  state: {
    authLoginResponse: {},
  },
  effects: {
    *authLogin({ params }, { call }) {
      const response = yield call(authLogin, params);
      if (response.success) {
        goUsers();
      } else {
        message.error(response.message);
      }
    },
  },
  reducers: {},
};

export default SelectCorpModel;
