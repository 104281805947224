import { parse } from 'querystring';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = path => reg.test(path);
export const isDev = process.env.NODE_ENV === 'development';
export const isLocal = !window.location.href.includes('.chartmo.com');
export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export function diffAry(a, b) {
  return a.concat(b).filter(v => !a.includes(v) || !b.includes(v));
}
