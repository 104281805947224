import { stringify } from 'querystring';
import { routerRedux } from 'dva/router';
import { getPageQuery } from '@/utils/utils';
import { setAuthority } from '@/utils/authority';
import { removeCookieForLogout } from '@/utils/cookies';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *logout(_, { put }) {
      removeCookieForLogout();
      // redirect
      const { redirect } = getPageQuery();

      if (window.location.pathname !== '/user/login' && !redirect) {
        yield put(
          routerRedux.replace({
            pathname: '/user/login',
            search: stringify({
              redirect: window.location.href,
            }),
          }),
        );
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return { ...state, status: payload.status, type: payload.type };
    },
  },
};
export default Model;
