import React from 'react';
import Avatar from './AvatarDropdown';

import styles from './index.less';

const GlobalHeaderRight = () => {
  const className = styles.right;
  return (
    <div className={className}>
      <Avatar />
    </div>
  );
};

export default GlobalHeaderRight;
