/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */
import React from 'react';
import { connect } from 'dva';
import ProLayout from '@ant-design/pro-layout';
import { formatMessage } from 'umi-plugin-react/locale';

import RightContent from '@/components/GlobalHeader/RightContent';

import logo from '../assets/logo.png';

const footerRender = () => <></>;

const BasicLayout = props => {
  const { children, settings } = props;

  return (
    <ProLayout
      logo={logo}
      formatMessage={formatMessage}
      rightContentRender={rightProps => <RightContent {...rightProps} />}
      footerRender={footerRender}
      {...props}
      {...settings}
    >
      {children}
    </ProLayout>
  );
};

export default connect(({ settings }) => ({
  settings,
}))(BasicLayout);
