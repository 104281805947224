import {
  queryUserList,
  toggleShowDemo,
  queryUserShowDemo,
  toggleUserActivated,
} from '@/services/users';

const UsersModel = {
  namespace: 'users',
  state: {
    userList: [],
    isShowDemo: true,
  },
  effects: {
    *toggleUserActivated({ params }, { call, put }) {
      const resUserList = yield call(toggleUserActivated, params);
      yield put({
        type: 'saveUserList',
        payload: resUserList.data,
      });
    },

    *toggleShowDemo(_, { call, put }) {
      const response = yield call(toggleShowDemo);
      let rs = true;
      if (response.data === '隐藏demo成功') {
        rs = false;
      }
      yield put({
        type: 'saveIsShowDemo',
        payload: rs,
      });
    },

    *fetchIsShowDemo(_, { call, put }) {
      const response = yield call(queryUserShowDemo);
      yield put({
        type: 'saveIsShowDemo',
        payload: response.data,
      });
    },

    *fetchUserList(_, { call, put }) {
      const response = yield call(queryUserList);
      yield put({
        type: 'saveUserList',
        payload: response.data,
      });
    },
  },
  reducers: {
    saveUserList(state, action) {
      return { ...state, userList: action.payload || {} };
    },
    saveIsShowDemo(state, action) {
      return { ...state, isShowDemo: action.payload };
    },
  },
};
export default UsersModel;
