import React, { Component } from 'react';

import { SERVER_DOMAIN, APPID } from '@/utils/CONST';
import { removeCookieForLogout } from '@/utils/cookies';
import DDlogin from '@/utils/DDlogin';

import styles from './style.less';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      APPID,
      REDIRECT_URI: `${SERVER_DOMAIN}/auth/qrcode/scan`,
    };
  }

  componentWillMount() {
    removeCookieForLogout();
    // 触发回调时处理回调链接,举例：如果查询字符串中含有state,且为dinglogin（可自行设置）,
    // 则触发扫描登录的相应处理方法，比如登录。
    const state = this.props.location && this.props.location.query.state;
    if (state === 'dinglogin') {
      // todo....
      // ex: dispatch(scanLogin({tmp_auth_code: code}));
    }
  }

  componentDidMount() {
    // 监听消息处理方法
    const handleMessage = event => {
      // 获取loginTempCode
      const loginTempCode = event.data;
      // 获取消息来源
      const { origin } = event;
      // 拼接 url
      const url = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${this.state.APPID}&response_type=code&scope=snsapi_login&state=dinglogin&redirect_uri=${this.state.REDIRECT_URI}&loginTmpCode=${loginTempCode}`;
      // 如果来源为https://login.dingtalk.com，则在当前窗口打开回调链接
      if (origin === 'https://login.dingtalk.com') {
        window.open(encodeURI(url), '_parent');
      }
    };
    // 监听iframe的消息
    if (typeof window.addEventListener !== 'undefined') {
      window.addEventListener('message', handleMessage, false);
    } else if (typeof window.attachEvent !== 'undefined') {
      window.attachEvent('onmessage', handleMessage);
    }
  }

  render() {
    const options = {
      id: 'login-container',
      goto: `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${this.state.APPID}&response_type=code&scope=snsapi_login&state=dinglogin&redirect_uri=${this.state.REDIRECT_URI}`,
      width: '230px',
      height: '320px',
      backgroundColor: 'white',
    };
    return (
      <div className={styles.pgLogin}>
        <section className={styles.body}>
          <div className={styles.left}>
            <div className={styles.bgFilm}></div>
            <div className={styles.bodyContent}>
              <header className={styles.bodyHeader}>
                <img
                  className={styles.logoImg}
                  src="https://chartmo.oss-cn-hangzhou.aliyuncs.com/PC_LOGO_WHITE.png"
                  alt=""
                />
              </header>
              <section className={styles.bodySection}>
                <img
                  className={styles.loginBgImage}
                  src="https://report-image-list.oss-cn-hangzhou.aliyuncs.com/dingtalk-admin-pic-tu.png"
                  alt=""
                />
              </section>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.loginQrImage}>
              <span
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                  paddingBottom: '4px',
                  borderBottom: '2px solid #3296FA',
                }}
              >
                钉钉扫码登录
              </span>
              <div id="login">
                <DDlogin options={options} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
